import React, { Component } from 'react'
import './Painting.css'

class Painting extends Component {

  render() {

    let k = this.props.k

    return (
      <div className="box sq Painting">
        <div className={`canvas  painting-${k}`}></div>
        <div className={`preload painting-${k+1}`}></div>
        <div className={`preload painting-${k+2}`}></div>
        <div className={`preload painting-${k+3}`}></div>
        <div className={`preload painting-${k+4}`}></div>
        <div className={`preload painting-${k+5}`}></div>
      </div>
    )

  }
}

export default Painting
