export const palette = {
  "C-1" : "mp3/ah.mp3",
  "C#-1": "mp3/dab1-pops.mp3",
  "D-1" : "mp3/dab2-wible.mp3",
  "D#-1": "mp3/notmuch.mp3",
  "E-1" : "mp3/dab4-more.mp3",
  "F-1" : "mp3/tap2.mp3",
  "F#-1": "mp3/garden2.mp3",
  "G-1" : "mp3/garden3.mp3",
  "G#-1": "mp3/garden4.mp3",
  "A-1" : "mp3/garden5.mp3",
  "A#-1": "mp3/guit1.mp3",
  "B-1": "mp3/guit2.mp3",
  "C0": "mp3/guit3.mp3",
  "C#0": "mp3/guit4.mp3",
  "D0": "mp3/guit5.mp3",
  "D#0": "mp3/museum1.mp3",
  "E0": "mp3/museum2.mp3",
  "F0" : "mp3/museum3.mp3",
  "F#0": "mp3/museum4.mp3",
  "G0": "mp3/museum5.mp3",
  "G#0": "mp3/museum6.mp3",
  "A0": "mp3/museum7.mp3",
  "A#0": "mp3/new1-fm.mp3",
  "B0": "mp3/new2-ping.mp3",
  "C1": "mp3/new3-fm2.mp3",
  "C#1": "mp3/new4.mp3",
  "D1": "mp3/new5-soft.mp3",
  "D#1": "mp3/new6.mp3",
  "E1": "mp3/pb1-posh.mp3",
  "F1": "mp3/pb2-post2.mp3",
  "F#1": "mp3/sppwcl1.mp3",
  "G1": "mp3/sppwcl2.mp3",
  "G#1": "mp3/sppwcl3-pot.mp3",
  "A1": "mp3/sppwcl4-fizzle.mp3",
  "A#1": "mp3/sppwcl5-pot2.mp3",
  "B1": "mp3/sppwcl6-thud.mp3",
  "C2": "mp3/taplong.mp3",
  "C#2": "mp3/tv1-hum.mp3",
  "D2": "mp3/tv2-chord.mp3",
  "D#2": "mp3/tv3-scifi.mp3",
  "E2": "mp3/tv5-theme.mp3",
  "F2": "mp3/garden6.mp3",
  "F#2": "mp3/garden7.mp3",
  "G2": "mp3/garden8.mp3",
  "G#2": "mp3/garden9.mp3",
  "A2": "mp3/dab1-pops.mp3", // again
  "A#2": "mp3/garden11.mp3",
  "B2": "mp3/garden11b.mp3",
}

// currently unused but probs should be
export const field = {
  "C-1" : "mp3/tap2.mp3",
  "C#-1": "mp3/sppwcl4b-fizzleb.mp3",
  "D-1": "mp3/notmuch.mp3",
  "C2": "mp3/taplong.mp3",
}
