/*

  SAMPLER
  vaguely generative keyboard noodling algorithm built on basic
  principles:
    1 subset:       select a subset of a larger group of samples
    2 noodle:       trigger a random sample from subset
    3 procession:   iteratively move the subset across the samples
                    here this is achieved by removing one 'on'
                    sample and adding one 'off' sample each
                    iteration

  the subset and rate can be edited by the user

*/

import React, { Component } from 'react'
import Tone from 'tone'

import { palette } from './palette'
import { prob, random } from './useful'

import './Sampler.css'

class Sampler extends Component {

  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      rate: 0.33,
      on: []
    }
    this.ready = this.ready.bind(this)
  }

  componentWillMount() {
    this.initPatch()
  }

  componentDidMount() {
    let master = Tone.Master
    this.crusher = new Tone.BitCrusher(11)
    this.filter = new Tone.AutoFilter(0.222).connect(this.crusher).start()
    this.sampler = new Tone.Sampler(palette, this.ready).connect(this.filter)
    setTimeout(() => { this.crusher.connect(master) }, 555) // avoid click
  }

  ready() {
    this.sampler.attack = 0.3
    this.sampler.release = 0.3
    this.setState({ready: true})
    this.noodle()
    this.evolve()
  }

  initPatch() {
    // pick between 5 and 7 of the samples and add to palette
    // set rate and wonk sliders, set pitch to normal
    // load the necessary ones here somehow?! maybe not nec

    let l = Object.keys(palette).length
    let nums = []
    for(let i = 0; i < l; i++) { nums.push(i) }
    let temp, k

    while (l--) {
      k = Math.floor((l + 1) * Math.random())
      temp = nums[k]
      nums[k] = nums[l]
      nums[l] = temp
    }

    let n = 5 + Math.floor(Math.random() * 5)
    let initSet = nums.slice(0, n);
    this.setState({on: initSet})

    let initRate = 0.33
    if(prob(0.5)) {
      initRate = 0.5 + (random() - 0.5)
    }
    this.setState({rate: initRate})
  }

  // handle slider changes
  tweak(e) {
    let knob = e.target.name
    let value = e.target.value / 333
    this.setState({ [knob] : value })
  }

  // handle checkbox changes
  toggle(i,e) {
    let on = this.state.on.splice(0)
    if(on.includes(i)) {
      let ind = on.indexOf(i)
      on.splice(ind,1)
    } else {
      this.trigger(i)
      on.push(i)
    }
    this.setState({on:on})
  }

  // trigger sample once
  trigger(i) {
    let note = Object.keys(palette)[i]
    this.sampler.releaseAll()
    let r = random()
    // max 555, min 0.222, usually 0.222 with 1/4 chance of being fucking high
    // let lfo = Math.min(555, 0.222 + Math.pow(r + 0.25,77))
    // max 333, linear and low up to 3/4, 1/4 chance of being fucking high
    let lfo = Math.min(333, 0.333 * r + Math.pow(r + 0.25,77))
    this.filter.frequency.value = lfo
    this.sampler.triggerAttack(note)
    if(prob(0.1)) {
      let delay = 111 + random(200)
     setTimeout(() => this.sampler.triggerAttack(note),delay)
    }
  }

  timing(i) {
    let buffers = this.sampler._buffers._buffers
    let max = buffers[i]._buffer.duration * 1000
    // TODO: probably this should be tied to rate
    if(prob(1 - this.state.rate)) { // long one
      return (0.66 + Math.random() * 0.33) * max
    } else { // short one
      return Math.max(222, random(max/3))
    }
  }

  noodle() {
    let on = this.state.on
    let samp = on[Math.floor(Math.random() * on.length)]
    if(samp) {
      this.trigger(samp)
      setTimeout(this.noodle.bind(this), this.timing(samp))
    } else  {
      setTimeout(this.noodle.bind(this), 555)
    }
  }

  // change patch once
  iterate() {
    if(this.state.rate !== 0) {
      this.props.paint()
      let on  = this.state.on.slice(0)
      // determine off
      let off = []
      for(let i = 0; i < Object.keys(palette).length; i++) {
        if(!on.includes(i)) off.push(i)
      }
      if(off.length > 0) {
        // switch a random on --> off
        let out = Math.floor(Math.random() * on.length)
        on.splice(out, 1)
        this.setState({on: on})
        // switch a random off --> on
        setTimeout(() => {
          on = this.state.on.splice(0) // changes may have occurred
          off = []
          for(let i = 0; i < Object.keys(palette).length; i++) {
            if(!on.includes(i)) off.push(i)
          }
          on.push(off[Math.floor(Math.random() * off.length)])
          this.setState({on: on})
        }, 555)
      }
    }
  }

  evolve() {
    this.iterate()
    let TIME_MAX = 11111
    let TIME_MIN = 3333
    let time = TIME_MAX - this.state.rate * (TIME_MAX - TIME_MIN)
    setTimeout(this.evolve.bind(this), time)
  }

  render() {

    let checkboxes = []
    for(let i = 0; i < Object.keys(palette).length; i++) {
      let isOn = this.state.on.includes(i)
      checkboxes.push(
        <input type="checkbox"
          className="checkbox"
          key={i}
          onChange={this.toggle.bind(this, i)}
          name={i}
          checked={isOn} />
      )
    }

    return (
      <div className="box other Sampler">

        <a className="thing" href="https://open.spotify.com/album/78jctDLSbWxdxIGyeDyp7A?si=ds5FlpqFSNiIlaidfh1U2Q" target="_blank" rel="noopener noreferrer">listen to</a>

        { this.state.ready ? (
          <div className="knobs">
            { checkboxes }
            <input type="range"
              className="param-slider"
              name="rate"
              onChange={this.tweak.bind(this)}
              onMouseUp={this.iterate.bind(this)}
              min="0"
              value={this.state.rate * 333}
              max="329" />
            <label htmlFor="rate">{" "+ (this.state.rate * 100).toFixed(0)}</label>
          </div>
        ) : (
          <div className="knobs">
            <em>loading things</em>
          </div>
        )

        }


        <a className="thing morsels" href="http://tastymorsels.org" target="_blank" rel="noopener noreferrer">.zip</a>

      </div>
    )
  }
}

export default Sampler
