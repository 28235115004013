import React, { Component } from 'react'

import './Activities.css'

class Activities extends Component {
  render() {
    return (
      <div className="box other Activities">
        <a className="thing top" href="https://open.spotify.com/album/78jctDLSbWxdxIGyeDyp7A?si=ds5FlpqFSNiIlaidfh1U2Q" target="_blank" rel="noopener noreferrer">listen to</a>
        <div className="thing mid"><em>loading things</em></div>
        <a className="thing morsels" href="http://tastymorsels.org" target="_blank" rel="noopener noreferrer">.zip</a>
      </div>
    )
  }
}

export default Activities
