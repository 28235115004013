// various helpers

export function prob(x) {
  let r = Math.random()
  return (r < x)
}

export function random(x) {
  if(typeof x === 'number') {
    return Math.random() * x
  } else if (Array.isArray(x) && x.length > 0) {
    return x[Math.floor(Math.random() * x.length)]
  } else {
    return Math.random()
  }
}
