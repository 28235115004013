import React, { Component } from 'react'

import './css.css'

import Painting from './Painting'
import Sampler from './Sampler'
import Activities from './Activities'

const MAX_K = 37

class Garden extends Component {

  constructor(props) {
    super(props)
    this.state = {
      k: 0,
      ready: false
    }
  }

  componentWillMount() {
    let k = Math.floor(Math.random() * MAX_K)
    this.setState({ k: k })
  }

  componentDidMount() {
    // hack: force mp3s to wait
    // sorry
    setTimeout(() => {
      this.setState({ready: true})
    }, 1111)
  }

  paint() {
    this.setState(p => {
      let next = (p.k + 1) % MAX_K
      return {k: next}
    })
  }

  readyForSampler() {
    this.setState({readyForSampler: true})
  }

  render() {
    return (
      <div className="Garden">
        <Painting k={this.state.k} />
        { this.state.ready ? <Sampler paint={this.paint.bind(this)} /> : <Activities paint={this.paint.bind(this)}/>}
      </div>
    )
  }
}

export default Garden
